import i18next from "i18next";
import React from "react";
import { SiCheckmarx } from 'react-icons/si'
import { Theme } from "../../Utils/globals";

interface QualificationsProps {
  currentTheme: Theme
}
interface QualificationsState {

}

class Qualifications extends React.Component<QualificationsProps, QualificationsState> {
  render(): React.ReactNode {
    return (
      <section id="qualifications">
        <div className="container mt-4 pt-4">
          <h1 className={`text-center ${this.props.currentTheme}`}>{i18next.t("Experience:Qualifications:Title")}</h1>
          <div className="row">
            <div className="col-lg-4 mt-4 qualifications-wrapper">
              <div className={`card servicesText ${this.props.currentTheme}`}>
                <div className={`card-body ${this.props.currentTheme}`}>
                  <div className={`servicesIcon ${this.props.currentTheme}`}>
                    <SiCheckmarx />
                  </div>
                  <h4 className="card-title mt-3">{i18next.t("Experience:Qualifications:Certificate1:Name")}</h4>
                  <p className="card-text mt-3">{i18next.t("Experience:Qualifications:Certificate1:Unit")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4 qualifications-wrapper">
              <div className={`card servicesText ${this.props.currentTheme}`}>
                <div className={`card-body ${this.props.currentTheme}`}>
                  <div className={`servicesIcon ${this.props.currentTheme}`}>
                    <SiCheckmarx />
                  </div>
                  <h4 className="card-title mt-3">{i18next.t("Experience:Qualifications:Certificate2:Name")}</h4>
                  <p className="card-text mt-3">{i18next.t("Experience:Qualifications:Certificate2:Unit")}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-4 qualifications-wrapper">
              <div className={`card servicesText ${this.props.currentTheme}`}>
                <div className={`card-body ${this.props.currentTheme}`}>
                  <div className={`servicesIcon ${this.props.currentTheme}`}>
                    <SiCheckmarx />
                  </div>
                  <h4 className="card-title mt-3">{i18next.t("Experience:Qualifications:Certificate3:Name")}</h4>
                  <p className="card-text mt-3">{i18next.t("Experience:Qualifications:Certificate3:Unit")}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 mt-4 qualifications-wrapper">
              <div className={`card servicesText ${this.props.currentTheme}`}>
                <div className={`card-body ${this.props.currentTheme}`}>
                  <div className={`servicesIcon ${this.props.currentTheme}`}>
                    <SiCheckmarx />
                  </div>
                  <h4 className="card-title mt-3">{i18next.t("Experience:Qualifications:Certificate4:Name")}</h4>
                  <p className="card-text mt-3">{i18next.t("Experience:Qualifications:Certificate4:Unit")}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-4 qualifications-wrapper">
              <div className={`card servicesText ${this.props.currentTheme}`}>
                <div className={`card-body ${this.props.currentTheme}`}>
                  <div className={`servicesIcon ${this.props.currentTheme}`}>
                    <SiCheckmarx />
                  </div>
                  <h4 className="card-title mt-3">{i18next.t("Experience:Qualifications:Certificate5:Name")}</h4>
                  <p className="card-text mt-3">{i18next.t("Experience:Qualifications:Certificate5:Unit")}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-4 qualifications-wrapper">
              <div className={`card servicesText ${this.props.currentTheme}`}>
                <div className={`card-body ${this.props.currentTheme}`}>
                  <div className={`servicesIcon ${this.props.currentTheme}`}>
                    <SiCheckmarx />
                  </div>
                  <h4 className="card-title mt-3">{i18next.t("Experience:Qualifications:Applications:Title")}</h4>
                  <ul>
                    <li>{i18next.t("Experience:Qualifications:Applications:App1")}</li>
                    <li>{i18next.t("Experience:Qualifications:Applications:App2")}</li>
                    <li>{i18next.t("Experience:Qualifications:Applications:App3")}</li>
                    <li>{i18next.t("Experience:Qualifications:Applications:App4")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Qualifications