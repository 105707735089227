import React from "react";
import { MdWork, MdSchool, MdChildFriendly } from 'react-icons/md'
import { Theme } from "../../Utils/globals";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import i18next from "i18next";

interface ExperienceProps {
  currentTheme: Theme
}
interface ExperienceState {

}

class Experience extends React.Component<ExperienceProps, ExperienceState> {
  render(): React.ReactNode {
    return (
      <section id="experience">
        <div className="container mt-4 pt-4">
        <h1 className={`text-center ${this.props.currentTheme}`}>{i18next.t("Experience:Title")}</h1>
        <VerticalTimeline lineColor={this.props.currentTheme == "light" ? "#f2dfbb" : "#3a250f"}>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: this.props.currentTheme == "light" ? '#cd8500' : '#2b3329' }}
            contentArrowStyle={{ borderRight: this.props.currentTheme == "light" ? '7px solid #cd8500' : "7px solid #2b3329" }}
            date={`${i18next.t("Experience:Work:Company2:Timeframe")}`}
            iconStyle={{ 
              background: this.props.currentTheme == "light" ? '#a0522d' : "#3e543c", 
              color: this.props.currentTheme == "light" ? '#f2dfbb' : "#b8f0b2" }}
            icon={<MdWork />}
          >
            <h3 className="vertical-timeline-element-title">{i18next.t("Experience:Work:Company2:JobTitle")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{i18next.t("Experience:Work:Company2:Location")}</h4>
            <p className={`${this.props.currentTheme}`}>
              {i18next.t("Experience:Work:Company2:Responsibilities")}
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: this.props.currentTheme == "light" ? '#cd8500' : '#2b3329' }}
            contentArrowStyle={{ borderRight: this.props.currentTheme == "light" ? '7px solid #cd8500' : "7px solid #2b3329" }}
            date={`${i18next.t("Experience:Work:Company1:Timeframe")}`}
            iconStyle={{ 
              background: this.props.currentTheme == "light" ? '#a0522d' : "#3e543c", 
              color: this.props.currentTheme == "light" ? '#f2dfbb' : "#b8f0b2" }}
            icon={<MdWork />}
          >
            <h3 className="vertical-timeline-element-title">{i18next.t("Experience:Work:Company1:JobTitle")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{i18next.t("Experience:Work:Company1:Location")}</h4>
            <p className={`${this.props.currentTheme}`}>
              {i18next.t("Experience:Work:Company1:Responsibilities")}
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{ background: this.props.currentTheme == "light" ? '#a0522d' : '#3e543c' }}
            contentArrowStyle={{ borderRight: this.props.currentTheme == "light" ? '7px solid #a0522d' : "7px solid #3e543c" }}
            date={`${i18next.t("Experience:Education:School2:Date")}`}
            iconStyle={{ 
              background: this.props.currentTheme == "light" ? '#a0522d' : "#3e543c", 
              color: this.props.currentTheme == "light" ? '#f2dfbb' : "#b8f0b2" }}
            icon={<MdSchool />}
          >
            <h3 className="vertical-timeline-element-title">{i18next.t("Experience:Education:School2:Name")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{i18next.t("Experience:Education:School2:Field")}{i18next.t("Experience:Education:School2:FieldEx")}</h4>
            <p className={`${this.props.currentTheme}`}>
              {i18next.t("Experience:Education:School2:Specialisation")}
              {i18next.t("Experience:Education:School2:SpecialisationEx")}
            </p>
            <p>
              {i18next.t("Experience:Education:School2:AchievedTitle")}
              {i18next.t("Experience:Education:School2:AchievedTitleEx")}
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{ background: this.props.currentTheme == "light" ? '#a0522d' : '#3e543c' }}
            contentArrowStyle={{ borderRight: this.props.currentTheme == "light" ? '7px solid #a0522d' : "7px solid #3e543c" }}
            date={`${i18next.t("Experience:Education:School1:Date")}`}
            iconStyle={{ 
              background: this.props.currentTheme == "light" ? '#a0522d' : "#3e543c", 
              color: this.props.currentTheme == "light" ? '#f2dfbb' : "#b8f0b2" }}
            icon={<MdSchool />}
          >
            <h3 className="vertical-timeline-element-title">{i18next.t("Experience:Education:School1:Name")}</h3>
            <h4 className="vertical-timeline-element-subtitle">{i18next.t("Experience:Education:School1:Field")}{i18next.t("Experience:Education:School1:FieldEx")}</h4>
            <p className={`${this.props.currentTheme}`}>
              {i18next.t("Experience:Education:School1:Specialisation")}
              {i18next.t("Experience:Education:School1:SpecialisationEx")}
            </p>
            <p className={`${this.props.currentTheme}`}>
              {i18next.t("Experience:Education:School1:AchievedTitle")}
              {i18next.t("Experience:Education:School1:AchievedTitleEx")}
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ 
              background: this.props.currentTheme == "light" ? '#a0522d' : "#3e543c", 
              color: this.props.currentTheme == "light" ? '#f2dfbb' : "#b8f0b2" }}
            icon={<MdChildFriendly />}
          />
        </VerticalTimeline>
        </div>
      </section>
    )
  }
}

export default Experience